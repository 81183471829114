import * as React from "react";
import JourneyImage from "../../components/JourneyImage";
import JourneyPageLayout, {
  CHAPTERS,
} from "../../components/JourneyPageLayout";
import uctImage from "../../images/journey/uct.jpg";
import ogImage from "../../images/og/journey-academic-life.jpg";

const JourneyAcademicLifePage = () => {
  return (
    <JourneyPageLayout
      pageTitle="Matthew Goslett's Academic Life"
      metaDescription="Matthew Goslett shares his journey as a graduate student at the University of Cape Town."
      og={{ image: ogImage }}
      chapter={CHAPTERS.ACADEMIC_LIFE}
    >
      <p>
        I was one of those students in school who probably could've done well if
        I tried, but I was more interested in computers, programming, and online
        gaming. The only exception to this was, well, computers, where I was
        already ahead of the syllabus. In high school, we were taught the Java
        programming language using the Ready to Program (RTP) (ewww) IDE. I'd
        picked up Java a few years back so was luckily able to coast through
        most of the work. I'd already open-sourced a few packages: jIRC - a
        library implementing the IRC protocol - and jEggyBot/GameBot - an
        eggdrop port. The latter was actually used for many years in the local
        gaming scene to run online pickup matches (friendly games) for
        Counter-Strike, Day of Defeat, and Call of Duty. I ended up handing this
        in as my final matric project and gracefully received the top mark in
        the class.
      </p>
      <p>
        As I approached the end of high school, I really wasn't sure what career
        path I wanted to follow. Computer Science would've been an obvious
        choice; however, I felt that a lot of my life had been consumed by
        computers and wanted to try move away from that. I applied for an LLB
        (Bachelor of Laws degree) at the University of Cape Town (UCT) but was
        rejected during pre-acceptance. I was forced to take an alternative path
        to the LLB and got pre-accepted for a BA undergrad majoring in law, with
        the intention of later doing a post-grad LLB.
      </p>
      <JourneyImage image={uctImage} />
      <p>
        I was required to take additional subjects such as Psychology,
        Philosophy, and Sociology, none of which I had any interest in. I lasted
        about a month before I transferred to commerce and enrolled in a B.Com.
        majoring in Accounting and Law. I did relatively well in my 1st year,
        but didn't receive the 70% aggregate needed to continue with law. I once
        again switched programmes, this time to a B.Com. C.A. stream.
      </p>
      <p>
        I struggled my way through the degree and recall many controversial and
        emotional discussions with my parents about dropping out and switching
        degrees. In my final year just as I was about to write mid-year exams, I
        remember sitting them down and discussing the possibility of switching
        to Computer Science - something I probably should've studied from the
        start. I opted to stick it out, though, and by some miracle, I scraped
        through the final exams and graduated with an undergrad. It wasn't a
        great ending though. I was 3% short of being allowed to do a
        Postgraduate Diploma in Accounting (PGDA) and my only option was to take
        an entrance exam over the summer holidays. I was so emotionally depleted
        by then, and so certain that continuing down this path would've been a
        mistake, that I decided to explore other options.
      </p>
      <p>
        The university's career guidance department suggested I look into a
        Postgraduate Diploma in Computer Forensics. This is a part-time 2-year
        diploma which can be converted into a Management Information Systems
        Honours upon completion of a research project. I was interested,
        particularly because of my passion for computers and background in
        accounting, so I enrolled and started class in 2011.
      </p>
    </JourneyPageLayout>
  );
};

export default JourneyAcademicLifePage;
